/* stylelint-disable selector-class-pattern */

@import '/src/styles/utilities.scss';

$check-mark: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%2364b969' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z'/></svg>");
$dot: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%231a48b8' viewBox='0 0 16 16'><path d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3'/></svg>");

.standardDatePicker {
  display: flex;

  :global(.react-datepicker__aria-live) {
    display: none;
  }

  :global(.react-datepicker__portal) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-popover;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100dvh;
    background-color: rgb(0 0 0 / 60%);
    backdrop-filter: blur(13px);
  }

  :global(.react-datepicker-popper) {
    z-index: $zindex-active;
  }
}

.standardDatePicker__calendar {
  @include media-breakpoint-down(sm) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 85dvh;
    border-radius: $border-radius-15 $border-radius-15 0 0;
  }

  padding: $space-8;
  font-family: $font-primary;
  background-color: var(--color-white);
  border-radius: $border-radius-15;
  box-shadow: $elevation-4;

  :global(.react-datepicker__day-names) {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    place-items: center;
    padding: $space-16 0;
    margin: 0 $space-16;
    font-weight: bold;
  }

  :global(.react-datepicker__day) {
    position: relative;
    width: $space-28;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    color: var(--color-gocomics-blue);
    text-align: center;
    border-radius: $border-radius-5;

    &[aria-disabled='true'] {
      color: var(--color-gray-300);

      &:hover {
        cursor: auto;
        background-color: transparent;
      }
    }

    &:active:not([aria-disabled='true']) {
      color: var(--color-white);

      // :hover has higher specificity than :active
      /* stylelint-disable-next-line declaration-no-important */
      background-color: var(--color-gocomics-blue) !important;
      border-radius: $border-radius-5;
    }

    &:hover:not([aria-disabled='true']) {
      color: var(--color-gocomics-blue);
      cursor: pointer;
      background-color: var(--color-blue-300);
    }
  }

  :global(.react-datepicker__week) {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    place-items: center;
    margin: 0 $space-16 $space-12;
    color: var(--color-gray-300);
  }

  .standardDatePicker__day {
    &:after {
      position: absolute;
      top: 90%;
      left: 50%;
      width: 20px;
      height: 20px;
      pointer-events: none;
      content: '';
      background-image: $dot;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transform: translate(-50%, -50%);
    }

    &:is([aria-disabled='true']):after {
      content: none;
      background-image: none;
    }

    &_viewed {
      color: var(--color-alert-success-300);

      &:after {
        content: '';
        background-image: $check-mark;
      }
    }

    &:hover:after {
      content: none;
      background-image: none;
    }
  }

  :global(.react-datepicker__day--selected) {
    color: var(--color-white);
    background-color: var(--color-gocomics-blue);

    &:after {
      content: '';
      background-image: none;
    }

    &:hover {
      background-color: var(--color-gocomics-blue);
    }
  }

  :global(.react-datepicker__day--keyboard-selected) {
    color: var(--color-gocomics-blue);
    background-color: var(--color-blue-300);
    border-radius: $border-radius-5;
  }

  :global(.react-datepicker__day--outside-month) {
    /* stylelint-disable declaration-no-important */
    color: var(--color-gray-300) !important;
    pointer-events: none;

    &:after {
      content: none;
      background-image: none;
    }
  }
}

.standardDatePicker__button {
  padding: $space-16 $space-24;
}

.header {
  display: flex;
  justify-content: center;
  margin: $space-8;

  &__selectBox {
    padding: $space-8 $space-36 $space-8 $space-16;
    margin: $space-8;
    font-weight: $font-weight-bold;
    color: var(--color-gocomics-blue);
    border-color: var(--color-gray-200);
    border-radius: $border-radius-5;
  }
}
