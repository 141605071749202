@import '/src/styles/utilities.scss';

.loadingDotsContainer {
  display: flex;
  flex-direction: column;
  gap: $space-48;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: $space-96 * 2 0;
}

.loadingDots {
  display: grid;
  height: var(--loader-height, 15px);
  aspect-ratio: 5;

  --dot-gradient: no-repeat
    radial-gradient(farthest-side, var(--dot-color) 94%, transparent);

  &:before,
  &:after {
    grid-area: 1/1;
    content: '';
    background:
      var(--dot-gradient) left,
      var(--dot-gradient) right;
    background-size: 20% 100%;
    animation: loader-spin 1s infinite;
  }

  &:after {
    background:
      var(--dot-gradient) calc(1 * 100% / 3),
      var(--dot-gradient) calc(2 * 100% / 3);
    background-size: 20% 100%;
    animation-direction: reverse;
  }
}

@keyframes loader-spin {
  80%,
  100% {
    transform: rotate(0.5turn);
  }
}
