@import '/src/styles/utilities.scss';

.upsell {
  display: grid;
  align-items: center;
  background-color: var(--color-gocomics-blue);
  border-radius: $border-radius-20;

  // If there's no image, go full width
  & > :only-child {
    grid-column: 1 / -1;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
    min-height: 300px;
  }
}

.upsell__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upsell__copy {
  padding: $space-48;
}
