@import '/src/styles/utilities.scss';

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

  // Do not use overflow-x: hidden; since it breaks sticky positioning
  overflow-x: clip;
}

.layout__content {
  display: flex;
  flex: 1;
  flex-direction: column;
}
