@import '/src/styles/utilities.scss';

.advertisingUnit__container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding-top: $space-4;
  padding-bottom: $space-4;
  text-align: center;
}

.advertisingUnit__leaderboard {
  // can be 300x250 or 320x50
  @include skeleton-screen(320px, 50px);

  @include media-breakpoint-only(lg) {
    @include skeleton-screen(728px, 90px);
  }

  @include media-breakpoint-up(xl) {
    @include skeleton-screen(970px, 250px);
  }

  // special header ad
  &_atf {
    @include skeleton-screen(320px, 90px);

    @include media-breakpoint-between(sm, md) {
      @include skeleton-screen(320px, 50px);
    }

    @include media-breakpoint-up(xl) {
      @include skeleton-screen(970px, 250px);
    }
  }

  // anchor can only be 320x50
  &_anchored {
    @include media-breakpoint-between(sm, md) {
      @include skeleton-screen(320px, 50px);
    }
  }

  // For in-content unit, allow 300x250
  &_mid {
    @include media-breakpoint-down(md) {
      @include skeleton-screen($width-ad-rail, 250px);
    }
  }
}

.advertisingUnit__rail {
  // can be 300x250 or 160x600 if flexed or stacked
  @include skeleton-screen($width-ad-rail, 600px);
}

.advertisingUnit__rectangle {
  @include skeleton-screen(320px, 50px);

  &_mid {
    @include media-breakpoint-down(md) {
      @include skeleton-screen($width-ad-rail, 250px);
    }
  }
}

.advertisingUnit__label {
  padding-top: $space-12;
}
