@import '/src/styles/utilities.scss';

.controls {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.controls__button {
  @include primary-stroked-effects();
  @include apply-display-styles('button');

  display: inline-flex;
  gap: $space-12;
  align-items: center;
  justify-content: center;
  height: $button-height-large;
  padding: 0 $space-12;
  color: var(--color-gocomics-blue);
  border-radius: $border-radius-50;

  &_previous {
    @include media-breakpoint-down(md) {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    @include media-breakpoint-only(md) {
      padding: 0 $space-36 0 $space-24;
    }
  }

  &_next {
    @include media-breakpoint-down(md) {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    @include media-breakpoint-only(md) {
      padding: 0 $space-24 0 $space-36;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: $button-padding-large;
  }
}

.controls__buttonText {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.controls__comicInfo {
  display: flex;
  gap: $space-24;
  align-items: center;
  justify-content: center;
}
