@import '/src/styles/utilities.scss';

.alert {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: $space-16;
  align-items: center;
  padding: $space-8;
  border-radius: $border-radius-5;

  &_info {
    background-color: var(--color-alert-info-100);
    border: 1px solid var(--color-alert-info-200);
  }

  &_success {
    background-color: var(--color-alert-success-100);
    border: 1px solid var(--color-alert-success-200);
  }

  &_warning {
    background-color: var(--color-alert-warning-100);
    border: 1px solid var(--color-alert-warning-200);
  }

  &_error {
    background-color: var(--color-alert-error-100);
    border: 1px solid var(--color-alert-error-200);
  }

  & + & {
    margin-top: $space-8;
  }
}

.alert__iconWrapper {
  display: inline-flex;
  padding: 6px;
  border-radius: $border-radius-50;

  &_info {
    background-color: var(--color-alert-info-300);
  }

  &_success {
    background-color: var(--color-alert-success-300);
  }

  &_warning {
    background-color: var(--color-alert-warning-300);

    svg {
      position: relative;
      top: -2px;
    }
  }

  &_error {
    background-color: var(--color-alert-error-300);

    svg {
      position: relative;
      top: -2px;
    }
  }
}

.alert__body {
  width: 100%;

  // Align the title and message
  div {
    display: inline;
  }
}

.alert__message {
  @include apply-display-styles('alert');
}

.alert__button {
  @include undo-default-button-styles();
}
