@import '/src/styles/utilities.scss';

$button-max-width: 340px;

.notFound__layout {
  @include create-container();
}

.notFound__content {
  display: grid;
  grid-template-areas: 'copy' 'image';
  grid-template-columns: 1fr;
  gap: $space-48;
  margin: $space-48 0 $space-48;

  @include media-breakpoint-up(md) {
    grid-template-areas: 'image copy';
    grid-template-columns: 1fr 1fr;
    gap: $space-48;
    align-items: center;
  }
}

.notFound__copy {
  grid-area: copy;
}

.notFound__heading {
  margin-bottom: $space-16;
  color: var(--color-gocomics-blue);
}

.notFound__paragraph {
  margin-bottom: $space-20;
}

.notFound__button {
  a,
  button {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: $button-max-width;
    }
  }
}

.notFound__image {
  grid-area: image;
  width: 100%;
  height: auto;
  object-fit: contain;
}
