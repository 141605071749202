@import '/src/styles/utilities.scss';

.upsell__copy {
  // A little extra spacing on top to clear the close button
  padding: $space-48 $space-20 $space-20;
}

.upsell__image {
  width: 100%;
  height: auto;
}
