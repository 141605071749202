@import '/src/styles/utilities.scss';

.typography {
  margin-bottom: 0;
}

.typography_d1 {
  @include apply-display-styles('d1');
}

.typography_d2 {
  @include apply-display-styles('d2');
}

.typography_d3 {
  @include apply-display-styles('d3');
}

.typography_d4 {
  @include apply-display-styles('d4');
}

.typography_d5 {
  @include apply-display-styles('d5');
}

.typography_d6 {
  @include apply-display-styles('d6');
}

.typography_subtitle1 {
  @include apply-display-styles('subtitle1');
}

.typography_subtitle2 {
  @include apply-display-styles('subtitle2');
}

.typography_body1 {
  @include apply-display-styles('body1');
}

.typography_body2 {
  @include apply-display-styles('body2');
}

.typography_body3 {
  @include apply-display-styles('body3');
}

.typography_body4 {
  @include apply-display-styles('body4');
}

.typography_body5 {
  @include apply-display-styles('body5');
}

.typography_navigation {
  @include apply-display-styles('navigation');
}

.typography_footerCaption {
  @include apply-display-styles('footerCaption');
}

.typography_button {
  @include apply-display-styles('button');
}

.typography_featureNav {
  @include apply-display-styles('featureNav');
}

.typography_alert {
  @include apply-display-styles('alert');
}

.typography_s1 {
  @include apply-display-styles('s1');
}

.typography_s2 {
  @include apply-display-styles('s2');
}
