// Text styling, fonts, displays

@mixin apply-display-styles($display) {
  @if $display == 'd1' {
    font-family: $font-primary;

    // https://fluid.style/type?min=3.5&max=5&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(3.5rem, 2.951rem + 1.463vw, 5rem);
    font-weight: $font-weight-extrabold;
    line-height: 1;
  }

  @if $display == 'd2' {
    font-family: $font-primary;

    // https://fluid.style/type?min=2.4&max=3.5&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(2.4rem, 1.998rem + 1.073vw, 3.5rem);
    font-weight: $font-weight-extrabold;
    line-height: 1;
  }

  @if $display == 'd3' {
    font-family: $font-primary;

    // https://fluid.style/type?min=1.8&max=2.4&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(1.8rem, 1.58rem + 0.585vw, 2.4rem);
    font-weight: $font-weight-extrabold;
    line-height: 1;
  }

  @if $display == 'd4' {
    font-family: $font-secondary;

    // https://fluid.style/type?min=1.8&max=2&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(1.8rem, 1.727rem + 0.195vw, 2rem);
    font-weight: $font-weight-medium;
    line-height: 1.25;
  }

  @if $display == 'd5' {
    font-family: $font-secondary;

    // https://fluid.style/type?min=1.6&max=1.8&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(1.6rem, 1.527rem + 0.195vw, 1.8rem);
    font-weight: $font-weight-semibold;
    line-height: 1.25;
  }

  @if $display == 'd6' {
    font-family: $font-primary;

    // https://fluid.style/type?min=1.6&max=1.8&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(1.6rem, 1.527rem + 0.195vw, 1.8rem);
    font-weight: $font-weight-extrabold;
    line-height: 1;
    letter-spacing: -0.18px;
  }

  @if $display == 'subtitle1' {
    font-family: $font-primary;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
    line-height: 1.25;
  }

  @if $display == 'subtitle2' {
    font-family: $font-primary;
    font-size: 1.4rem;
    font-weight: $font-weight-regular;
    line-height: 1.25;
  }

  @if $display == 'body1' {
    font-family: $font-secondary;

    // https://fluid.style/type?min=1.6&max=1.8&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(1.6rem, 1.527rem + 0.195vw, 1.8rem);
    font-weight: $font-weight-regular;
    line-height: 1.25;
  }

  @if $display == 'body2' {
    font-family: $font-secondary;
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    line-height: 1.25;
  }

  @if $display == 'body3' {
    font-family: $font-paragraph;
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    line-height: 1.5;
  }

  @if $display == 'body4' {
    font-family: $font-paragraph;
    font-size: 1.4rem;
    font-weight: $font-weight-regular;
    line-height: 1.5;
  }

  @if $display == 'body5' {
    font-family: $font-primary;
    font-size: 1.4rem;
    font-weight: $font-weight-medium;
    line-height: 1.25;
  }

  @if $display == 'navigation' {
    font-family: $font-primary;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    line-height: 1.15;
  }

  @if $display == 'footerCaption' {
    font-family: $font-secondary;
    font-size: 1.4rem;
    font-weight: $font-weight-regular;
    line-height: 1.3;
  }

  @if $display == 'button' {
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    white-space: nowrap;
  }

  // TODO: see if we can rename this to 'buttonSmall' since it's mostly used there
  @if $display == 'featureNav' {
    font-family: $font-primary;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    letter-spacing: -0.3px;
    white-space: nowrap;
  }

  @if $display == 'alert' {
    font-family: $font-primary;
    font-size: 1.4rem;
    font-weight: $font-weight-light;
    line-height: 1.15;
    letter-spacing: -0.28px;
  }

  @if $display == 's1' {
    font-family: $font-secondary;

    // https://fluid.style/type?min=2.4&max=3.4&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(2.4rem, 2.034rem + 0.976vw, 3.4rem);
    font-weight: $font-weight-regular;
    line-height: 1.2;
  }

  @if $display == 's2' {
    font-family: $font-secondary;

    // https://fluid.style/type?min=1.8&max=2.4&min-bp=37.5&max-bp=140&unit=%22rem%22
    font-size: clamp(1.8rem, 1.58rem + 0.585vw, 2.4rem);
    font-weight: $font-weight-regular;
    line-height: 1;
  }
}

@mixin line-clamp($line-count: 2) {
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;
}

@mixin apply-link-styles($color, $underline: null) {
  // Apply underline if 'underline' is passed as 2nd argument
  @if $underline == 'underline' {
    text-decoration: underline;
  } @else {
    text-decoration: none;
  }

  // Apply color styles
  @if $color == 'color-white' {
    color: var(--color-white);

    &:hover,
    &:focus {
      color: var(--color-gray-100);
    }

    &:active {
      color: var(--color-gray-200);
    }
  }

  @if $color == 'color-gocomics-blue' {
    color: var(--color-gocomics-blue);

    &:hover,
    &:focus {
      color: var(--color-alert-info-300);
    }

    &:active {
      color: var(--color-gocomics-blue);
    }
  }
}
