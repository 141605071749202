// Base font size
$font-size-base: 1.6rem;

// Font families and available weights for each
// See also: src/helpers/fonts/index.js
// regular 400, medium 500, bold 700, extra bold 800
$font-primary: var(--font-work-sans), sans-serif;

// regular 400, medium 500, semibold 600
$font-secondary: var(--font-newsreader), serif;

// regular 400
$font-paragraph: var(--font-merriweather), serif;

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
