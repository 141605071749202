// Form inputs, selects, validation

@mixin form-input() {
  display: block;
  width: 100%;
  padding: $space-8 $space-12;
  font-family: $font-primary;
  font-size: 1.6rem;
  line-height: 1.5;
  color: var(--color-text-dark);
  appearance: none;
  background-color: var(--color-white);
  background-clip: padding-box;

  // Default border styles
  border: $form-border-width solid var(--color-gray-300);
  border-radius: $border-radius-10;
  outline: none;
  box-shadow: 0 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out;

  &:hover {
    @include form-input-hover();
  }

  &:focus {
    @include form-input-focus();
  }
}

@mixin form-input-hover() {
  border: $form-border-width solid var(--color-gray-300);
  outline: none;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 0%),
    0 0 0 1px var(--color-gray-300);
}

@mixin form-input-focus($important: false) {
  // Sometimes form inputs are used with third party libs that need !important
  @if $important {
    /* stylelint-disable declaration-no-important */
    border: $form-border-width solid var(--color-gocomics-blue) !important;
    outline: none;
    box-shadow:
      0 0 0 0 rgb(0 0 0 / 0%),
      0 0 0 1px var(--color-gocomics-blue) !important;
    /* stylelint-enable declaration-no-important */
  } @else {
    border: $form-border-width solid var(--color-gocomics-blue);
    outline: none;
    box-shadow:
      0 0 0 0 rgb(0 0 0 / 0%),
      0 0 0 1px var(--color-gocomics-blue);
  }
}

@mixin form-select-style() {
  @include form-input();

  // Add a chevron to the select since we removed the default one
  // https://icons.getbootstrap.com/icons/chevron-down/
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/></svg>");
  background-repeat: no-repeat;
  background-position: center right 1.8rem;
  background-size: 1.8rem;
}
