// Z-index list
// All z-indexes should be organized here so that we have a bird's eye view
// of how they stack.
$zindex-active: 1;
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-toast: 1090;
$zindex-sticky-ad: 2000;
