// Reusable UI components

@mixin active-expanded() {
  &[aria-expanded='true'] {
    &:after {
      position: absolute;
      right: 50%;
      bottom: 8px;
      left: 50%;
      z-index: $zindex-active;
      width: 16px;
      height: 16px;
      margin-left: 8px;
      text-align: center;
      content: '';
      background-color: var(--color-white);
      border-radius: 50%;
    }
  }
}

@mixin box-style($padding: $space-16, $box-shadow: $elevation-5) {
  padding: $padding;
  border-radius: $border-radius-15;
  box-shadow: $elevation-5;
}

@mixin skeleton-screen($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
}
