// Positioning, containers, grids

@mixin create-container() {
  position: relative;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-only(xs) {
    width: min($container-xs, 100% - ($gutter * 2));
  }

  @include media-breakpoint-only(sm) {
    width: min($container-sm, 100% - ($gutter * 2));
  }

  @include media-breakpoint-only(md) {
    width: min($container-md, 100% - ($gutter * 2));
  }

  @include media-breakpoint-only(lg) {
    width: min($container-lg, 100% - ($gutter * 2));
  }

  @include media-breakpoint-only(xl) {
    width: min($container-xl, 100% - ($gutter * 2));
  }

  @include media-breakpoint-only(xxl) {
    width: min($container-xxl, 100% - ($gutter * 2));
  }
}
