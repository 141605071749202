@use 'sass:map';

// Breakpoints, media queries

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints-map: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints-map);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints-map: $breakpoints) {
  $max: breakpoint-max($name, $breakpoints-map);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints-map: $breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints-map) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints-map) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints-map: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints-map);
  $max: breakpoint-max($name, $breakpoints-map);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints-map) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints-map) {
      @content;
    }
  }
}

// Uses CSS Variables to set a property value at every breakpoint
// Requires: CSS Variables for each breakpoint in a .jsx component,
//   like '--margin-xs': '3.2rem', '--margin-sm': '0.8rem', etc
// See: responsiveCSSProperties.js for a function to create the
//   variables for use in a component.
// Usage: @include responsive-properties('padding', 0);
@mixin responsive-properties($property, $fallback: inherit) {
  @include media-breakpoint-only(xs) {
    #{$property}: var(--#{$property}-xs, $fallback);
  }

  @include media-breakpoint-only(sm) {
    #{$property}: var(--#{$property}-sm, $fallback);
  }

  @include media-breakpoint-only(md) {
    #{$property}: var(--#{$property}-md, $fallback);
  }

  @include media-breakpoint-only(lg) {
    #{$property}: var(--#{$property}-lg, $fallback);
  }

  @include media-breakpoint-only(xl) {
    #{$property}: var(--#{$property}-xl, $fallback);
  }

  @include media-breakpoint-only(xxl) {
    #{$property}: var(--#{$property}-xxl, $fallback);
  }
}
