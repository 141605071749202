@import '/src/styles/utilities.scss';

.label {
  position: relative;
  display: flex;
  flex-direction: column;

  &:focus-within:not(&_success, &_warning, &_error) {
    color: var(--color-gocomics-blue);
  }

  &_success {
    color: var(--color-alert-success-300);
  }

  &_warning {
    color: var(--color-alert-warning-300);
  }

  &_error {
    color: var(--color-alert-error-300);
  }
}

.label__text {
  // The width preserves some space for the input's optional character counter
  width: calc(100% - 7ch - $space-20);
  padding-bottom: $space-4;
  font-family: $font-primary;
  font-size: 1.4rem;
  font-weight: bold;

  &_hidden {
    visibility: hidden;
  }
}

.label__feedbackMessage {
  display: flex;
  justify-content: space-between;
  min-height: 1.5em;
  padding-top: $space-4;
  padding-bottom: $space-4;
  font-family: $font-primary;
  font-size: 1.4rem;
  font-weight: bold;
  color: inherit;
}
