@import '/src/styles/utilities.scss';

.fiveFavorites {
  flex-grow: 1;
}

.fiveFavorites__header {
  position: relative;
  padding-bottom: $space-80;
  text-align: center;
}

.fiveFavorites__badgeWrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
}

.fiveFavorites__badge {
  width: var(--favorites-image-size);
}

.fiveFavorites__comics {
  padding: $space-80 $space-28 $space-40;
  background-color: var(--color-white);
  border-radius: $border-radius-25;

  @include media-breakpoint-up(md) {
    padding: $space-80 $space-40 $space-40;
  }

  @include media-breakpoint-up(lg) {
    border-radius: $border-radius-40;
  }
}

.fiveFavorites__divider {
  margin: $space-32 0;
}
