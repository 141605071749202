@import '/src/styles/utilities.scss';

.avatar {
  position: relative;
  width: var(--avatar-size, 100%);
  max-width: var(--max-avatar-size, 100%);
  height: auto;
  max-height: var(--max-avatar-size, 100%);
  aspect-ratio: 1/1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
