@import '/src/styles/utilities.scss';

.skipLink {
  position: absolute;
  top: 0;
  right: 100%;
  z-index: $zindex-toast;

  &:focus {
    right: auto;
    padding: $space-12 $space-20;
    font-size: 2rem;
    color: var(--color-gray-700);
    background-color: var(--color-gray-200);
    border-color: var(--color-gocomics-blue);
  }
}
