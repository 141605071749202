@import '/src/styles/utilities.scss';

.textContent {
  display: flex;
  flex-direction: column;
  gap: $space-12;
  align-items: flex-start;
  justify-content: center;
  color: var(--color-text-light);
  text-align: left;

  // Remove margin from text styles
  & > * {
    margin-bottom: 0;
  }

  // Remove margin from trailing WYSIWYG text
  *:last-child {
    margin-bottom: 0;
  }

  &_isCenter {
    align-items: center;
    text-align: center;
  }
}

.textContent__auth {
  display: inline;
  font-family: $font-primary;
  font-size: 1.6rem;
}

.textContent__signIn {
  @include undo-default-button-styles();
  @include apply-link-styles('color-white', 'underline');
}
