@import '/src/styles/utilities.scss';

.buttonArrow {
  @include undo-default-button-styles();

  display: flex;
  align-items: center;
  justify-content: center;
  padding: $space-4;

  &:focus {
    border: 2px solid var(--color-gocomics-blue);
    border-radius: $border-radius-5;
  }
}
