@import '/src/styles/utilities.scss';

$animation-duration: 666ms;
$upsell-max-height: 80dvh;

.upsell {
  position: fixed;
  top: var(--header-content-height, 0);
  bottom: 0;
  z-index: $zindex-fixed;
  width: 100%;
}

.upsell__background {
  // Eat all clicks nom nom
  position: absolute;
  inset: 0;
  /* stylelint-disable declaration-no-important */
  pointer-events: none !important;
  touch-action: none !important;
  /* stylelint-enable declaration-no-important */
  background-color: color-opacity(var(--color-gray-600), 30%);
  animation: fade-in $animation-duration ease-in;
}

.upsell__container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: min(100%, #{$upsell-max-height});
  overflow: auto;
  animation: slide-up-from-bottom $animation-duration
    cubic-bezier(0.55, 0, 1, 0.45);
}

.upsell__contents {
  display: grid;
  grid-template-rows: minmax(100px, 1fr) auto;
  place-items: center;
  min-height: 100%;
  background-color: var(--color-gocomics-blue);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  // If there's no image, go full width
  & > :only-child {
    grid-column: 1 / -1;
  }

  @include media-breakpoint-up(md) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
  }
}

.upsell__imageContainer {
  position: relative; // supports .upsell__image
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.upsell__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upsell__copy {
  padding: $space-12;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-up-from-bottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
