@import '/src/styles/utilities.scss';

.card {
  display: inline-block;
  max-width: 100%;
  height: 100%;
  border-radius: $border-radius-20;
  transition: all 0.3s ease;

  &_isPrimary {
    aspect-ratio: 176 / 262;
    background-color: var(--color-white);
    box-shadow: $elevation-1;
  }

  &_isSecondary {
    aspect-ratio: 161 / 220;

    // We can have a little padding on top, as a treat
    padding-top: $space-4;
    background-color: transparent;
  }

  &:hover {
    background-color: var(--color-gray-200);
    box-shadow: none;
  }

  &:active {
    background-color: var(--color-gray-200);
  }
}

.card__header {
  padding: $space-20 $space-20 0;
}

.card__body {
  display: flex;
  flex-direction: column;
  gap: $space-4;
  padding: $space-12;
  text-align: center;

  > * {
    // Title
    color: var(--color-gray-800);

    // Sub Title
    &:last-child:not(:only-child) {
      color: var(--color-gray-700);
    }
  }
}
