@import '/src/styles/utilities.scss';

.toolbar {
  display: flex;
  gap: $space-8;
  align-items: center;
  color: var(--color-gocomics-blue);
}

.toolbar__button {
  @include toolbar-button-effects();

  display: flex;
  gap: $space-8;
  align-items: center;
  height: $button-height-small;
}

.toolbar__text {
  font-family: $font-primary;
  font-size: 1.6rem;
  font-weight: $font-weight-bold;
}
