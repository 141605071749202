@import '/src/styles/utilities.scss';

$modal-gutter: $space-12;
$close-icon-offset: $space-4;
$close-icon-size: 44px;

:export {
  var_iconSize: $close-icon-size;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: $zindex-modal-backdrop;
  display: grid;
  place-items: center;
  overflow-y: auto;
  backdrop-filter: blur(13px);

  &_transparent {
    background-color: rgb(0 0 0 / 0%);
  }

  &_light {
    background-color: rgb(0 0 0 / 45%);
  }

  &_medium {
    background-color: rgb(0 0 0 / 60%);
  }

  &_dark {
    background-color: rgb(0 0 0 / 75%);
  }

  &_opaque {
    background-color: rgb(0 0 0 / 100%);
  }

  &[data-state='open'] {
    animation: overlay-enter 150ms ease-out;
  }

  &[data-state='closed'] {
    animation: overlay-exit 150ms ease-in;
  }
}

.modal {
  width: calc(100vw - ($modal-gutter * 2));
  max-width: 475px;
  margin-top: $modal-gutter;
  margin-bottom: $modal-gutter;

  &_wide {
    max-width: 600px;
  }

  &_wider {
    max-width: 800px;
  }

  &[data-state='open'] {
    animation: modal-enter 150ms ease-out;
  }

  &[data-state='closed'] {
    animation: modal-exit 150ms ease-in;
  }
}

// This handles the radius and background of the modal. Since it needs to hide
// overflow, it needs to be nested within .modal, otherwise overflow breaks
// scroll when there's a lot of content.
.modal__container {
  overflow: hidden;
  background-color: var(--background-color);
  border-radius: $border-radius-15;
}

.modal__header {
  position: relative;
  width: 100%;
}

.modal__close {
  @include undo-default-button-styles();

  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $close-icon-size;
  height: $close-icon-size;
  color: var(--close-icon-color);
  background-color: var(--background-color);

  // This border and background clip acts like margin around the button. We
  // don't want the button to look too big, so this helps make it appear
  // smaller despite its fixed size.
  background-clip: padding-box;
  border: $close-icon-offset solid transparent;
  border-radius: 50%;

  &:focus-visible {
    background-color: color-darken(var(--background-color), 10%);
    outline: none;
  }
}

.modal__content {
  padding: var(--padding-amount);
}

@keyframes overlay-enter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes overlay-exit {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes modal-enter {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modal-exit {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.9);
  }
}
