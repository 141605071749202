@import '/src/styles/utilities.scss';

.buttonPrimary {
  text-transform: capitalize;
  text-wrap: nowrap;
  cursor: pointer;
  border-radius: $border-radius-50;

  &:is(a):not(.buttonPrimary__icon) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.buttonPrimary__fullWidth {
  width: 100%;
}

.buttonPrimary_filled {
  @include primary-filled-effects();
}

.buttonPrimary_stroked {
  @include primary-stroked-effects();
}

.buttonPrimary_sm {
  @include apply-display-styles('featureNav');

  height: $button-height-small;
  padding: $button-padding-small;
}

.buttonPrimary_lg {
  @include apply-display-styles('button');

  height: $button-height-large;
  padding: $button-padding-large;
}

.buttonPrimary__icon {
  @include with-icon();
}
