@import '/src/styles/utilities.scss';

.upsellSectionBreak {
  &_top {
    padding: 0 0 $space-56;
  }

  &_middle {
    padding: $space-48 0 $space-48;
    background-color: var(--color-blue-100);
  }

  &_bottom {
    padding: $space-48 0 $space-48;
    background-color: var(--color-blue-100);
  }
}
