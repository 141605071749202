// Navbar heights
$height-nav-mobile: 5rem;
$height-nav-desktop: 7.7rem;

// Feature header height
$height-feature-header-desktop: 18.6rem;

// Puzzle header height
$height-puzzle-nav: 5.9rem;

// Ads
$width-ad-rail: 300px;
