@import '/src/styles/utilities.scss';

.inputField {
  position: relative;
}

.inputField__counter {
  position: absolute;
  top: 0;
  right: 0;
  font-family: $font-primary;
  font-size: 1.4rem;
  font-weight: bold;
  transform: translateY(-100%);
}

.inputField__input {
  @include form-input();
}
